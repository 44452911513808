<!--
 * @Description: 资金流水
 * @Author: ChenXueLin
 * @Date: 2021-09-24 14:01:10
 * @LastEditTime: 2022-07-15 16:14:18
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="流水编号"
                title="流水编号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="typeId">
              <e6-vr-select
                v-model="searchForm.typeId"
                :data="typeList"
                placeholder="流水类型"
                title="流水类型"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="打款名称"
                title="打款名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="转账流水号/交易单号"
                title="转账流水号/交易单号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="typeId">
              <e6-vr-select
                v-model="searchForm.typeId"
                :data="typeList"
                placeholder="交易方式"
                title="交易方式"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="所属人"
                title="所属人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="typeId">
              <e6-vr-select
                v-model="searchForm.typeId"
                :data="typeList"
                placeholder="所属类型"
                title="所属类型"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="交易时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="交易时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="交易时间（始）"
                end-placeholder="交易时间（止）"
              ></e6-date-picker>
            </el-form-item>

            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-export_fill" title="导出"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight - 10 + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
        >
          <!-- <el-table-column type="selection" width="40"></el-table-column> -->
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
export default {
  name: "",
  components: {},
  data() {
    return {
      searchForm: {
        orderNo: "",
        typeId: "",
        secondType: "",
        pageIndex: 1,
        pageSize: 20
      },
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ],
      total: 0,
      columnData: [
        {
          fieldName: "label",
          display: true,
          fieldLabel: "序列号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "renwudandao",
          display: true,
          fieldLabel: "SIM卡号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "kehumingcheng",
          display: true,
          fieldLabel: "中心识别码",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "kehujiancheng",
          display: true,
          fieldLabel: "持有者",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "suoshuxiangmu",
          display: true,
          fieldLabel: "持有者类型",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "orderType",
          display: true,
          fieldLabel: "SIM卡套餐",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "status",
          display: true,
          fieldLabel: "套餐每月流量（M）",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color",
          color: "#79a3f2"
        },
        {
          fieldName: "orderCount",
          display: true,
          fieldLabel: "月租费（元）",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "流量池编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "卡属性",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "卡类型",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "卡状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "已绑定设备",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "已绑定设备编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "供应商",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "是否自有卡",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "是否需要报停",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "开通日期",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "报停日期",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "报停原因",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [
        {
          label: ["有变更", "有更近", "有退会"],
          noDis: "111",
          status: 1
        },
        {
          label: ["有变更", "有更近", "有退会"],
          noDis: "111",
          status: 2
        },
        {
          label: ["有变更", "有更近", "有退会"],
          noDis: "111",
          status: 3
        }
      ] // 表格数据
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  methods: {
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val) {
      if (val.id == 1) {
        this.routerPush({
          name: "settlementManage/billingDetail",
          params: {
            refresh: true
          }
        });
      }
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped></style>
