<!--
 * @Description: 款项管理
 * @Author: ChenXueLin
 * @Date: 2021-09-24 14:00:14
 * @LastEditTime: 2022-07-15 16:46:06
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div class="content-view section-wrapper" ref="sectionWrapper">
      <!-- <tab-pane :tabList="tabList" @handleTab="handleTab"></tab-pane> -->
      <moneyRecord v-if="paneType == '1'"></moneyRecord>
      <receivableClaim v-if="paneType == '2'"></receivableClaim>
      <my-claim v-if="paneType == '3'"></my-claim>
    </div>
  </div>
</template>

<script>
// import base from "@/mixins/base";
// import listPage from "@/mixins/list-page";
// import listPageReszie from "@/mixins/list-page-resize";
// import tabPane from "@/components/tabPane";
import moneyRecord from "./components/moneyRecord.vue";
import myClaim from "./components/myClaim.vue";
import receivableClaim from "./components/receivableClaim.vue";
export default {
  name: "paymentManage",
  data() {
    return {
      editVisible: false,
      paneType: "1", //1列表页，2配置页
      tabList: [
        {
          label: "资金流水",
          name: "1"
        },
        {
          label: "回款认领",
          name: "2"
        },
        {
          label: "我的认领",
          name: "3"
        }
      ],
      editForm: {}
    };
  },
  //   mixins: [base, listPage, listPageReszie],

  computed: {},
  components: {
    // tabPane,
    moneyRecord,
    myClaim,
    receivableClaim
  },
  mounted() {},

  methods: {
    toDetail() {
      this.routerPush({
        name: "taskListManage/detail",
        params: {
          refresh: true
        }
      });
    },
    //创建任务单
    createTask() {
      this.routerPush({
        name: "taskListManage/add",
        params: {
          refresh: true
        }
      });
    },
    handleTab(val) {
      this.paneType = val;
    },
    handleClose() {}
  }
};
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding: 20px;
  padding-top: 0px;
  box-sizing: border-box;
}

// /deep/.el-tabs__header {
//   margin-left: 0px;
//   padding-left: 10px;
//   box-sizing: border-box;
//   border-bottom: 1px solid #ebeef5;
// }
.list-box {
  .config {
    display: flex;
    align-items: center;
    margin-top: 40px;
    .til1 {
      color: #545456;
      width: 100px;
      text-align: right;
      margin-right: 50px;
    }
    .til2 {
      width: 250px;
      color: #8b8b8b;
    }
  }
}
.edit-dialog {
  padding: 30px;
  box-sizing: border-box;
}
</style>
